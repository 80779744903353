import React from "react";
import { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { Auth } from "aws-amplify";
import moment from "moment-timezone";
import withViewer from "@/graphql/withViewer"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  async componentDidCatch(error, errorInfo) {
    // https://docs.sentry.io/platforms/javascript/react/
    this.setState({ error });
    let user = null;
    try {
      const data = await Auth.currentAuthenticatedUser();
      const payload = data?.signInUserSession?.idToken?.payload ?? null;
      if (payload) {
        user = {
          id: payload.user_id,
          email: payload.email,
          username: payload.sub
        };
      }
    } catch (error) { }
    Sentry.setExtra(errorInfo);
    if (user) {
      Sentry.setUser(user);
    }
    const eventId = Sentry.captureException(error);
    this.setState({ eventId });

  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="content">
          <img width="128px" src="/static/rain.png" />
          <h1 className="title">Something went wrong here...</h1>
          <h1 className="subtitle">
            Sorry. We're having some technical issues. Try refreshing the page.
          </h1>
          <h1 className="subtitle">
            Click <a href="/">here</a> to go back
          </h1>
          <a
            onClick={() =>
              Sentry.showReportDialog({ eventId: this.state.eventId })
            }
          >
            Report feedback
          </a>
          <style>{`
                        * {
                            box-sizing: border-box;
                            font-size: 1.0em;
                            font-smooth: always;
                            background-color: #EDF1F5;
                        }
                        h1{
                            font-size: 2.0em;
                        }
                        .content{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            min-height: 100vh;
                        }
                        .title{
                           margin: 1.0em;
                           font-size: 2.2em;
                           color: #3798a9;
                        }
                        .subtitle{
                            font-size: 1.2em;
                            color: #AAA;
                        }
                    `}</style>
        </div>
      );
    }
    return this.props.children;
  }
}

const setDefaultTimezone = (viewerData) => {
  const orgTz = viewerData?.currentOrganization?.timezone;
  const onAdminPage = window.location.pathname.startsWith('/admin');
  let timezone = null;
  if (onAdminPage) {
    timezone = moment.tz.guess();
  } else if (orgTz) {
    timezone = orgTz
  }
  if (timezone && timezone !== moment().tz()) {
    moment.tz.setDefault(timezone);
  }
}

const App = ({ children, viewerData }) => {
  useEffect(() => {
    setDefaultTimezone(viewerData);
  }, [viewerData]);

  return (
    <ErrorBoundary>
      <>
        {children}
      </>
    </ErrorBoundary>
  )
};

export default withViewer(App)
