import App from "@/components/App";
import PageLoading from "@/components/PageLoading";
import Header from "@/components/MarketingHeader";
import { withStateHandlers, withState, compose, lifecycle } from "recompose";
import Amplify from "@/lib/aws";
import Link from "next/link";
import { withRouter, useRouter } from "next/router";
import Router from "next/router";
import { Input, Form, Alert } from "antd";
import Button from "@/components/Button";
import Panel from "@/components/Panel";
import { useState, useRef, useEffect } from "react";
import { useAuth } from "@/hooks/useAuth";
import { Modal, Layout } from "antd";
import { WarningTwoTone } from "@ant-design/icons";
import {getAuthSession} from "../lib/cognito";

const FormItem = Form.Item;

const ButtonStyle = {
  marginTop: "10px",
  width: "100%",
  justifyContent: "center",
};

const onSubmit = (evt, step, onLogin, onChangePassword, signIn, signInWithUser) => {
  evt.preventDefault();
  if (step === "NEW_PASSWORD_REQUIRED") {
    onChangePassword(evt, signInWithUser);
  } else {
    onLogin(evt, signIn);
  }
};

const SessionTimedOutBanner = (props) => {
  const router = useRouter();
  const { sessionTimedOut } = router.query;
  if (!sessionTimedOut) {
    return null;
  }
  return (
    <div className="alert">
      <Alert
        message="Your session has timed out after 15 minutes of inactivity"
        type="warning"
        showIcon
        closable
      />
      <style jsx>{`
        .alert :global(.ant-alert-close-icon) {
          background: none;
        }
      `}</style>
    </div>
  );
};

const LogOutBanner = (props) => {
  const router = useRouter();
  const { loggedOut } = router.query;
  if (!loggedOut) {
    return null;
  }
  return (
    <div className="alert">
      <Alert
        message="You have been logged out"
        type="warning"
        showIcon
        closable
      />
      <style jsx>{`
        .alert :global(.ant-alert-close-icon) {
          background: none;
        }
      `}</style>
    </div>
  );
};

// TODO: Bear put this in its own component
const MfaSplashScreen = (props) => {
  const {
    showMfaSplashScreen,
    setShowMfaSplashScreen,
    user,
  } = props;
  const router = useRouter();

  if (!showMfaSplashScreen)
    return null;
  return (
    <Modal
      title="MFA splash screen"
      visible={showMfaSplashScreen}
      onOk={() => router.push("/reservations")}
      closable={false}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Layout>
        <Layout.Sider style={{ background: 'white' }}>
          <WarningTwoTone style={{ fontSize: '500%', width: '100%' }} />
        </Layout.Sider>
        <Layout>
          <Layout.Content style={{ background: 'white' }}>For improved security, MFA is requried for staff users.</Layout.Content>
        </Layout>
      </Layout>
    </Modal>
  );
};

const MfaLogin = (props) => {
  const { signInWithUser } = useAuth();
  const [mfaCode, setMfaCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const mfaInput = useRef();
  const { user, resetForm } = props;

  const onReset = () => {
    resetForm();
    Router.push("/");
  };

  useEffect(() => {
    mfaInput.current.focus();
  }, [mfaInput]);

  const onSubmitMfa = async () => {
    setErrorMsg("");
    setLoading(true);
    try {
      const loggedUser = await Amplify.Auth.confirmSignIn(
        user,
        mfaCode,
        "SOFTWARE_TOKEN_MFA"
      );
      signInWithUser(loggedUser);
      return Router.push("/reservations");
    } catch (error) {
      console.log(error);
      setErrorMsg("Invalid Verification Code");
      setLoading(false);
    }
  };

  return (
    <div className="__container">
      <div className="instructions">
        Please enter an MFA code to complete sign-in.
      </div>
      <Input
        disabled={loading}
        data-cy="mfa"
        ref={mfaInput}
        onChange={(evt) => setMfaCode(evt.target.value)}
        onKeyDown={(evt) => {
          {
            if (evt.keyCode === 13) {
              onSubmitMfa();
            }
          }
        }}
        value={mfaCode}
        placeholder="6-digit MFA Code"
        style={{ marginBottom: "1.0em", width: "100%" }}
      />
      <Button
        data-cy="submitMfaButton"
        type="primary"
        loading={loading}
        style={{ width: "100%", marginBottom: "2.0em" }}
        onClick={(evt) => onSubmitMfa()}
      >
        Submit
      </Button>
      <p className="error">{errorMsg}</p>
      <div className="sublinks">
        <a onClick={(evt) => onReset()} data-cy="cancelLink" className="link">
          Cancel
        </a>
      </div>
      <style jsx>{`
        .__container {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        .instructions {
          font-size: 1em;
          font-weight: 500;
          margin: 1em 0;
        }
        .sublinks {
          display: flex;
          flex-direction: row;
        }
      `}</style>
    </div>
  );
};

const Login = ({
  username,
  password,
  checkingAuth,
  onUpdateValue,
  onLogin,
  isLoading,
  onChangePassword,
  newPassword,
  errorMessage,
  step,
  setErrorMessage,
  setStep,
  setUser,
  setUsername,
  setPassword,
  setIsLoading,
  user,
  showMfaSplashScreen,
  setShowMfaSplashScreen,
}) => {
  const { signIn, signInWithUser } = useAuth();

  const resetForm = () => {
    setErrorMessage("");
    setStep("BEGIN");
    setUser(null);
    onUpdateValue("username", "");
    onUpdateValue("password", "");
    setIsLoading(false);
  };

  const onHandleEnterPressed = (evt) => {
    if (evt.keyCode === 13) {
      onLogin(evt, signIn);
    }
  };

  if (checkingAuth) return <PageLoading />; 
  const panelTitle =
    step === "SOFTWARE_TOKEN_MFA"
      ? "Multi-factor Authentication"
      : "Welcome back! Please login";
  return (
    <App>
      <SessionTimedOutBanner />
      <LogOutBanner />
      <MfaSplashScreen
        showMfaSplashScreen={showMfaSplashScreen}
        setShowMfaSplashScreen={setShowMfaSplashScreen}
        user={user} />
      <Header />
      <div className="__container">
        <Panel
          title={panelTitle}
          containerStyle={{
            width: "90%",
            maxWidth: "500px",
            boxShadow: "box-shadow: 0px 5px 5px rgba(0, 0, 0, .25)",
          }}
        >
          {step === "SOFTWARE_TOKEN_MFA" && (
            <MfaLogin user={user} resetForm={resetForm} />
          )}
          {step !== "SOFTWARE_TOKEN_MFA" && (
            <Form
              onSubmit={(evt) => onSubmit(evt, step, onLogin, onChangePassword, signIn, signInWithUser)}
            >
              <div className="input-container">
                <label>Log in with your e-mail address</label>
                <Input
                  data-cy="username"
                  autoComplete="username"
                  onChange={(evt) =>
                    onUpdateValue("username", evt.target.value)
                  }
                  value={username}
                  type="text"
                  onKeyDown={onHandleEnterPressed}
                  placeholder="john@smith.com"
                />
                <label>Password</label>
                <Input
                  data-cy="password"
                  autoComplete="current-password"
                  onChange={(evt) =>
                    onUpdateValue("password", evt.target.value)
                  }
                  value={password}
                  type="password"
                  onKeyDown={onHandleEnterPressed}
                  placeholder="Numbers,Letters..."
                />
                {step === "NEW_PASSWORD_REQUIRED" && (
                  <div className="input-container">
                    <p className="required-reset">
                      Looks like a password reset is required
                    </p>
                    <div>
                      Passwords must contain:
                      <ul>
                        <li>a minimum of 1 lower case letter [a-z] and</li>
                        <li>a minimum of 1 upper case letter [A-Z] and</li>
                        <li>a minimum of 1 numeric character [0-9] and</li>
                        <li>{`a minimum of 1 special character: ~\`!@#$%^&*()-_+={}[]|\;:"<>,./? and`}</li>
                        <li>a minimum of 8 characters in length</li>
                      </ul>
                    </div>
                    <Input.Password
                      data-cy="currentPassword"
                      autoComplete="current-password"
                      onChange={(evt) =>
                        onUpdateValue("newPassword", evt.target.value)
                      }
                      value={newPassword}
                      type="password"
                      placeholder="new password"
                    />
                  </div>
                )}
                {step !== "NEW_PASSWORD_REQUIRED" && (
                  <FormItem>
                    <Button
                      data-cy="signInButton"
                      type="primary"
                      loading={isLoading}
                      style={ButtonStyle}
                      onClick={(evt) => onLogin(evt, signIn)}
                    >
                      Sign in to Dashboard
                    </Button>
                  </FormItem>
                )}
                {step === "NEW_PASSWORD_REQUIRED" && (
                  <Button
                    type="primary"
                    loading={isLoading}
                    style={ButtonStyle}
                    onClick={(evt) => onChangePassword(evt, signInWithUser)}
                  >
                    Confirm New Password
                  </Button>
                )}
                <p className="error">{errorMessage}</p>
                <Input
                  data-cy="newPassword"
                  type="submit"
                  style={{ display: "none" }}
                ></Input>
              </div>
              <div className="sublinks">
                <div style={{ flex: 1 }} />
                <Link prefetch href="/forgot-password">
                  <a data-cy="forgotPasswordLink" className="link">
                    Forgot your password?
                  </a>
                </Link>
              </div>
            </Form>
          )}
        </Panel>
      </div>
      <style jsx>{`
        h1 {
          padding-bottom: 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
        .__container {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 1;
        }
        .input-container {
          display: flex;
          flex-direction: column;
        }
        button {
          margin-top: 10px;
        }
        label {
          margin: 10px 5px;
        }
        .sublinks {
          display: flex;
          flex-direction: row;
        }
        .card {
          display: flex;
          flex-direction: column;
          flex: 1;
          margin-top: 20px;
          width: 90%;
        }
        .required-reset {
          margin: 15px 0px;
          font-weight: bold;
          color: #555;
        }
        @media (min-width: 900px) {
          .card {
            max-width: 500px;
          }
        }
      `}</style>
    </App>
  );
};

export default withRouter(
  compose(
    withState("errorMessage", "setErrorMessage", ""),
    withState("user", "setUser", null),
    withState("step", "setStep", "BEGIN"),
    withState("checkingAuth", "setCheckingAuth", true),
    withState("isLoading", "setIsLoading", false),
    withState('showMfaSplashScreen', 'setShowMfaSplashScreen', false),
    lifecycle({
      async componentDidMount() {
        try {
          const session = await getAuthSession(); //await Amplify.Auth.currentSession();
          return Router.push("/reservations");
        } catch (e) { }
        this.props.setCheckingAuth(false);
      },
    }),
    withStateHandlers(
      {
        username: "",
        password: "",
        newPassword: "",
      },
      {
        onUpdateValue: (props, state) => (key, val) => ({ [key]: val }),
        onLogin: (props, state) => async (evt, signIn) => {
          evt.preventDefault();
          state.setIsLoading(true);
          localStorage.removeItem('sso-login')
          try {
            state.setErrorMessage("");
            const user = await signIn(
              props.username.trim(),
              props.password.trim()
            );

            if (
              user.challengeName &&
              user.challengeName === "NEW_PASSWORD_REQUIRED"
            ) {
              state.setStep("NEW_PASSWORD_REQUIRED");
              state.setUser(user);
              state.setIsLoading(false);
              return;
            }
            if (
              user.challengeName &&
              user.challengeName === "SOFTWARE_TOKEN_MFA"
            ) {
              state.setStep("SOFTWARE_TOKEN_MFA");
              state.setUser(user);
              state.setIsLoading(false);
              return;
            }
            

            // TODO: Bear refactor this to use viewer{} query... getUser is staff only
            // const { data: { viewer: { id: userId } } } = await state.client.query({query: VIEWER_ID});
            // const { data: { getUser: { showMfaSplashScreen } } } = await state.client.query({
            //   query: MFA_SPLASH_SCREEN_QUERY,
            //   variables: { id: userId }
            // });

            // if (showMfaSplashScreen === true && user.preferredMFA === 'NOMFA') {
            //   state.setShowMfaSplashScreen(true);
            //   return;
            // }

            state.router.push("/reservations");
          } catch (error) {
            // console.log(error);
            state.setErrorMessage("Incorrect username or password.");
            state.setIsLoading(false);
          }
        },
        onChangePassword: (props, state) => async (evt, signInWithUser) => {
          evt.preventDefault();
          try {
            state.setErrorMessage("");
            state.setIsLoading(true);
            //https://docs.aws.amazon.com/cognito/latest/developerguide/using-amazon-cognito-identity-user-pools-javascript-example-authenticating-admin-created-user.html
            state.user.completeNewPasswordChallenge(props.newPassword, null, {
              onSuccess: function (result) {
                signInWithUser(result);
                state.setIsLoading(false);
                state.router.push("/reservations");
              },
              onFailure: function (err) {
                console.log(err);
                state.setIsLoading(false);
                state.setErrorMessage(err.message);
              },
            });
          } catch (err) {
            console.log(err);
            state.setErrorMessage(err.message);
          }
        },
      }
    )
  )(Login)
);