import React from "react";

const PageLoading = () => (
  <React.Fragment>
    <div className="__container">
      <div className="logo-container">
        <img src="/static/wellsky_logo2x.png" width="150px" />
      </div>
      <div className="line">
        <div className="inner"></div>
      </div>
      <style jsx>{`
        .__container {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          background-color: #e2e8ef;
          align-items: center;
          padding-top: 50px;
          z-index: 99999;
        }

        .logo-container {
            margin: 2em;
        }

        @keyframes slidein {
          0% {
            transform-origin: left;
            transform: scalex(0.3);
          }
          25% {
            transform-origin: left;
            transform: scalex(1);
          }
          26% {
            transform-origin: right;
            transform: scalex(1);
          }
          50% {
            transform-origin: right;
            transform: scalex(0.3);
          }
          75% {
            transform-origin: right;
            transform: scalex(1);
          }
          76% {
            transform-origin: left;
            transform: scalex(1);
          }
          100% {
            transform-origin: left;
            transform: scalex(0.3);
          }
        }

        .line {
          height: 3px;
          width: 200px;
          background: #00897b26;
        }
        .inner {
          height: 3px;
          width: 200px;
          background: #00897b;
          animation: slidein 2s ease-in-out infinite;
        }
      `}</style>
    </div>
  </React.Fragment>
);

export default PageLoading;
