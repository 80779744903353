import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { POLICY_DEFAULTS } from '@/constants';
import { canUsePolicy } from '@/lib/utils';

const VIEWER = gql`
query viewer($organizationSettingKey: String!, $nextToken: String, $limit: Int) {
	viewer{
		id
		fullName
		email
		photo
		isStaff
		currentPolicyMap
		clearCareToken
		notesServiceToken
		currentOrganization {
			id
			name
			photo
            status
			enabledAsReferrer
			featureFlags {
				key
			}
			clearCareLinkedAgency {
				agencyId
			}
			getOrganizationSettingValue(key:$organizationSettingKey, defaultValue: "") {
				key
				value
			}
			timezone
			type {
				code
			}
			programs {
				id
				name
			}
			featureFlags {
				key
				active
			}
		}
    	currentAccount{
			user {
				userGroups(limit: $limit, nextToken: $nextToken) {
					items {
						id
						tags
						name
					}
				}
			}
		}
		widgetUserToken
		customFormsToken
		customFormsTokenWithMapShare
	}
}
`;

const Component = WrappedComponent => props => {
	const variables = { organizationSettingKey: props.organizationSettingKey || "", limit: 25, nextToken: null}
	return (
	<Query query={VIEWER} variables={variables}>
		{({ loading, error, data, refetch }) => {
			var viewerData = data && data.viewer;
			return <WrappedComponent
				{...props}
				viewerLoading={loading}
				viewerError={error}
				viewerData={viewerData}
				viewerRefetch={refetch}
				canViewPolicy={policy => canUsePolicy(viewerData, policy, ['CREATE', 'UPDATE', 'DELETE', 'VIEW', 'MANAGE'])}
				canManagePolicy={policy => canUsePolicy(viewerData, policy, ['MANAGE'])}
				canCreatePolicy={policy => canUsePolicy(viewerData, policy, ['CREATE', 'MANAGE'])}
				canUpdatePolicy={policy => canUsePolicy(viewerData, policy, ['UPDATE', 'MANAGE'])}
				canDeletePolicy={policy => canUsePolicy(viewerData, policy, ['DELETE', 'MANAGE'])}
			></WrappedComponent>
		}}
	</Query>
)
	}

export default Component;
