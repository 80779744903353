import { Button } from "antd";

const Component = (props) => {
  const { type, disabled, className } = props;
  let buttonStyle = { ...props?.style };
  const primaryButtonStyle = {
    borderColor: "rgb(225, 126, 37)",
    backgroundColor: "rgb(225, 126, 37)",
    color: "white",
    border: "none",
  };

  const darkPrimaryButtonStyle = {
    backgroundColor: "#253646",
    color: "white",
    border: "none",
  };

  if (type === "primary") {
    buttonStyle = { ...primaryButtonStyle, ...buttonStyle };
  }

  if (type === "dark") {
    buttonStyle = { ...darkPrimaryButtonStyle, ...buttonStyle };
  }

  if (disabled == true) {
    buttonStyle = { ...buttonStyle, opacity: "0.50" };
  }
  return <Button {...props} style={buttonStyle} className={className} />;
};

export default Component;
