import { withRouter } from "next/router";
import Router from "next/router";
import WellSkyLogo from "@/components/WellSkyLogo";
import {signOut as cognitoSignOut} from "../lib/cognito";

const signOut = async (evt) => {
  evt.preventDefault();
  try {
    const result = await cognitoSignOut(); //Amplify.Auth.signOut();
    Router.push("/");
  } catch (e) {
    console.log(e);
  }
};

const Header = ({ session, router: { pathname } }) => {
  return (
    <>
      <header>
        <WellSkyLogo />
      </header>

      <style jsx>{`
        header {
          height: 60px;
          padding: 0;
          background-color: #28939d;
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        a {
          font-size: 14px;
          margin-right: 15px;
          text-decoration: none;
          color: white;
        }

        .logout {
          cursor: pointer;
        }

        .is-active {
          text-decoration: underline;
        }

        .logo-container {
          margin: 2em;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </>
  );
};

export default withRouter(Header);
