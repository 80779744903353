const Component = (props) => {
  return (
    <div className={"logo-container"}>
      <img
        src="/static/wellsky-logo-transparent-bg.png"
        className={"logo"}
      />
      <span className={"logo-unicode"}>&reg;</span>
      <div className={"logo-slogan"}>Care Coordination</div>
      <style jsx>{`
        .logo {
          height: 30px;
          margin: 0;
          padding: 0;
        }
        
        .logo-unicode {
          margin-top: 4.5em;
          margin-left: -.9em;
          font-size: 0.3125em;
          margin-right: 4.0em;
        }

        .logo-container {
          display: flex;
          align-items: center;
          height: 60px;
          margin: 0;
          padding: 0 1em;
        }

        .logo-slogan {
          padding: 0 1em;
          font-weight: 400;
          white-space: nowrap;
          user-select: none;
          font-size: 1.375em;
          color: #000000;
          border-left: 1px solid #D38235;
        }

        @media (max-width: 1500px) {
          .logo-slogan {
            display: none;
          }

        @media (max-width: 1350px) {
          .logo-slogan {
            display: block;
          }

        @media (max-width: 700px) {
          .logo-slogan {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default Component;
