import { X } from "react-feather";
import { Affix } from "antd";

const Header = (props) => {
  const { showCloseButton, onClose, title, contentRight } = props;
  return (
    <div className="header row">
      <h1>{title}</h1>
      <div style={{ flex: 1 }} />
      {contentRight && contentRight}
      {showCloseButton && (
        <div onClick={(evt) => onClose()} className="close-btn">
          <X color="#999" />
        </div>
      )}
      <style jsx>{`
        .header {
          padding: 1rem 1.25rem;
          border-bottom: 1px solid #e6eaee;
          background-color: #fafbfc;
          align-items: center;
        }
        h1 {
          margin: 0;
          padding: 0;
          font-size: 1.45em;
          color: #354052;
          font-weight: 500;
          user-select: none;
        }
        .close-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 3px;
          border-radius: 50%;
          transition: background-color 0.3s;
          cursor: pointer;
        }
        .close-btn:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      `}</style>
    </div>
  );
};

const Panel = (props) => {
  const {
    containerStyle,
    contentStyle,
    showCloseButton,
    onClose,
    contentRight,
    noHeader,
    fullScreen,
  } = props;

  return (
    <div
      onClick={(evt) => evt.stopPropagation()}
      className="__container"
      style={containerStyle}
    >
      {!noHeader && !fullScreen && (
        <Affix offsetTop={60}>
          <Header {...props} />
        </Affix>
      )}
      {!noHeader && fullScreen && <Header {...props} />}
      <div className="content" style={contentStyle}>
        {props.children}
      </div>
      <style jsx>{`
        .__container {
          position: relative;
          margin: 15px 15px;
          font-size: 1em;
          border: 1px solid #e6eaee;
          border-radius: 4px;
          background-color: #fff;
          overflow: auto;
        }
        .content {
          margin: 15px 15px;
          font-size: 1em;
          min-height: 200px;
        }
        @media (max-width: 900px) {
          .__container{
            flex: 1;
            min-width: auto !important;
          }
        }
      `}</style>
    </div>
  );
};

export default Panel;
